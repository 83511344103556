<template>
  <div>
    <div class="subtitle-1 d-flex">
      <div class="mr-4 d-flex align-center">
        <span>新增社群資料</span>
      </div>
    </div>
    <v-row class="pb-5">
      <v-col cols="12" class="d-flex align-center justify-space-between">
        <img class="mr-2" src="@/assets/img/social-icon-facebook.svg" alt="">
        <v-text-field
          dense
          outlined
          hide-details
          placeholder="請輸入社群網址"
          v-model="formData.fb"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" class="d-flex align-center justify-space-between">
        <img class="mr-2" src="@/assets/img/social-icon-instagram.svg" alt="">
        <v-text-field
          dense
          outlined
          hide-details
          placeholder="請輸入社群網址"
          v-model="formData.ig"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" class="d-flex align-center justify-space-between">
        <img class="mr-2" src="@/assets/img/social-icon-youtube.svg" alt="">
        <v-text-field
          dense
          outlined
          hide-details
          placeholder="請輸入社群網址"
          v-model="formData.youtube"
        >
        </v-text-field>
      </v-col>
    </v-row>
  </div>  
</template>
<script type="text/babel">
import formItemMixin from "@/components/form/mixins/formItemMixin";

export default {
  mixins: [formItemMixin],
  data() {
    return {
      
    };
  },
  computed: {
    
  },
  watch: {

  },
  mounted() {
    
  },
  methods: {
   
  },
};
</script>
